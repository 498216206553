import styled from "styled-components";
import WhatsAppImg from "../imgs/whatsapp.png"
import BackImg from "../imgs/back.jpg"

export const AppContainer = styled.div`
    height: 100%;
    width: 100%;
    background: url(${BackImg});
    background-size: cover;

    color: black;
`

export const NavLink = styled.a`
    outline: none;
    display: flex;
    justify-content: center;
    text-decoration: none;

    @media (max-width: 600px) {
        justify-content: center;
    }
`

export const WhatsappButton = styled.div`
    border-radius: 50%;
    width: 60px;
    height: 60px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(${WhatsAppImg});
    background-position: center;
    background-size: 50px;
    background-repeat: no-repeat;
    bottom: 0;
    right: 0;
    z-index: 100;
    margin-right: 10px;
    margin-bottom: 15px;

    &:hover {
        cursor: pointer;
    }

    @media (min-width: 900px) {
        width: 80px;
        height: 80px;
        background-size: 80px;
    }
`