import React from 'react'
import {ContactContainer, ContactLeftContainer, ContactRightContainer, ContactContainerHeading, ContactContainerText, ContactEndContainer, ContactDirectionsImage} from "./styles/styled.contact"
import {BsFillTelephoneFill} from "react-icons/bs"
import {MdEmail} from "react-icons/md";
import {HiLocationMarker} from "react-icons/hi"
import DirectionsImg from "./imgs/directions.jpg";
import {NavLink} from "./styles/styled.appcontainer"

function Contact() {
  return (
    <ContactContainer id="contact">
        <ContactLeftContainer>
            <ContactContainerHeading>Contact</ContactContainerHeading>
            <ContactContainerText>
            <BsFillTelephoneFill style={{'marginRight': "10px"}} />065 911 3810
            </ContactContainerText>
            <ContactContainerText>
            <MdEmail style={{'marginRight': "10px"}} />abdulazizcader@gmail.com
            </ContactContainerText>
            <ContactContainerText>
            <HiLocationMarker style={{'marginRight': "10px"}} />17 Reynolds Street, Port Shepstone, KZN
            </ContactContainerText>
        </ContactLeftContainer>

        <ContactRightContainer>
            <ContactContainerHeading>Operating Hours</ContactContainerHeading>
            <ContactContainerText>Mon-Fri - 8am-4pm</ContactContainerText>
            <ContactContainerText>Saturday - Closed</ContactContainerText>
            <ContactContainerText>Public Holidays - Closed</ContactContainerText>
        </ContactRightContainer>

        <ContactEndContainer>
            <NavLink href="https://goo.gl/maps/x4diHFv6u2W8hXbE9" target="_blank"><ContactDirectionsImage src={DirectionsImg} /></NavLink>
        </ContactEndContainer>
        
    </ContactContainer>
  )
}

export default Contact