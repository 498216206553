import React from 'react'
import {CardItemImageHolder, CardsContainer, CardsItem, CardsItemImage, CardsItemText, CardsItemTextHolder} from "./styles/styled.treatments"
import TickImg from "./imgs/tick.png";

function Treatments() {
  return (
    <CardsContainer>
        <CardsItem>
            <CardItemImageHolder>
            <CardsItemImage src={TickImg} />    
            </CardItemImageHolder>
            <CardsItemTextHolder>
            <CardsItemText>Experienced Physiotherapists</CardsItemText>
            </CardsItemTextHolder>
        </CardsItem>

        <CardsItem>
            <CardItemImageHolder>
            <CardsItemImage src={TickImg} />    
            </CardItemImageHolder>
            <CardsItemTextHolder>
            <CardsItemText>Thorough diagnosis and assessments</CardsItemText>
            </CardsItemTextHolder>
        </CardsItem>

        <CardsItem>
            <CardItemImageHolder>
            <CardsItemImage src={TickImg} />    
            </CardItemImageHolder>
            <CardsItemTextHolder>
            <CardsItemText>Open on Saturdays</CardsItemText>
            </CardsItemTextHolder>
        </CardsItem>

        <CardsItem>
            <CardItemImageHolder>
            <CardsItemImage src={TickImg} />    
            </CardItemImageHolder>
            <CardsItemTextHolder>
            <CardsItemText>Walk-in's accepted</CardsItemText>
            </CardsItemTextHolder>
        </CardsItem>

        <CardsItem>
            <CardItemImageHolder>
            <CardsItemImage src={TickImg} />    
            </CardItemImageHolder>
            <CardsItemTextHolder>
            <CardsItemText>Medical Aid rates</CardsItemText>
            </CardsItemTextHolder>
        </CardsItem>
    </CardsContainer>
  )
}

export default Treatments;