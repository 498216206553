import React, {useRef} from 'react'
import { HeroContainer, Video, Image, VideoContainer, VideoOverlay, HeroSection, HeroMenu, HeroButton, HeroSkew, HeroHolder, HeroTitle, HeroSubtitle, HeroHeading } from "./styles/styled.hero";
import Vid from "./vids/hero.mp4"
import {NavLink} from "./styles/styled.appcontainer"

function Hero() {
const videoRef = useRef();
  const videoSpeed = () => {
      if(videoRef.current) videoRef.current.playbackRate = 0.8;
  }

  return (

    <HeroContainer id="home">
          
          <HeroSection>
            
            <HeroHeading
            initial={{y: -10, opacity: 0}}
            animate={{
              y: 0, 
              opacity: 1,
              transition: { duration: 1, ease: "easeInOut"}
            }}
            >Advanced Therapy</HeroHeading>
            <HeroSubtitle>Specializing in physiotherapeutic treatments to help you restore your health and well-being.</HeroSubtitle>
            <HeroTitle>Abdul Aziz Cader</HeroTitle>
            <HeroTitle>BSc.Phys (UWC) PR: 0671401</HeroTitle>
            <NavLink href='tel:0659113810' target="_blank"><HeroButton>Book now</HeroButton></NavLink> 
            </HeroSection>
            
            {/* <VideoOverlay />
            <VideoContainer>
            
            <Video type="video/mp4" onCanPlay={videoSpeed} ref={videoRef} src={Vid} autoPlay loop muted playsInline  />    
            </VideoContainer> */}
      </HeroContainer>

  )
}

export default Hero