import React from 'react'
import {AboutContainer, AboutHolder, AboutLeftContainer, AboutRightContainer, AboutHeading, AboutText, AboutImage, AboutContainerHolder, AboutTextContainer} from "./styles/styled.about";
import AboutImg from "./imgs/about.png";

function About() {
  return (
    <AboutContainer id="about">
        <AboutHolder>
        <AboutLeftContainer>
            <AboutTextContainer>
              <AboutHeading>Hello.</AboutHeading>
              <AboutText>I'm Abdul Aziz. At Advanced Therapy, I aim to treat the cause of the your problem and not only the symptoms concerned. Patients are given the "tools" and means to re-gain their pre-injury and functional status as well as to maintain and make long-term improvements.
              </AboutText>
            </AboutTextContainer>
          
        </AboutLeftContainer>

        </AboutHolder>
        
    </AboutContainer>
  )
}

export default About