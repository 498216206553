import styled from "styled-components";


export const CarouselContainer = styled.div`
    overflow: hidden;
    border-radius: 5px;
    
    padding: 20px 20px;
`

export const CarouselInner = styled.div`
    transform: translateX(-${props => (props.index * 100)}%);
    white-space: nowrap;
    transition: transform 0.3s;
    
`

export const CarouselStyleItem = styled.div`
    display: inline-flex;
    height: 160px;
    align-items: center;
    justify-content: center;
    width: 100%;
    
`

export const CarouselHolderHeading = styled.h1`
    width: 100%;
    text-align: center;
    margin: 20px 0px;
    font-weight: 800;
    margin-top: 30px;
    font-size: 2.5rem;
    text-transform: uppercase;
    color: #4361ee;
`

export const CarouselTextHolder = styled.div`
    min-width: 300px;
    background-color: rgba(33, 37, 41, 0.8);
    padding: 20px 0px;
    height: 100%;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: absolute;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`
export const CarouselText = styled.p`
    white-space: pre-wrap;      /* Webkit */    
    word-wrap: break-word;      /* IE */
    padding : 10px;
    max-width: 250px;
    display: flex;
    flex: 70%;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 2;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;

    @media (max-width: 400px) {
        font-size: 0.9rem;
    }
`

export const CarouselTextAuthor = styled.p`
    display: flex;
    flex: 30%;
    align-items: flex-end;
    width: 100%;
    color: white;
    display: flex;
    font-style: italic;
    justify-content: flex-end;
    font-size: 0.7rem;
    font-weight: 600;
    margin-right: 40px;
`