import React, {useEffect} from 'react'
import { InfoContainer, InfoCardContainerMainHolder, InfoCardContainerHolder, InfoCardContainerText, InfoItemContainer, InfoItemBackground, InfoImageContainer, InfoImage, InfoItemHeading, InfoItemSubHeading, InfoItemText, InfoCardContainer, InfoCard, InfoCardImage, InfoCardText, InfoHolder } from './styles/styled.info'
import {CardItemImageHolder, CardsContainer, CardsItem, CardsItemImage, CardsItemText, CardsItemTextHolder} from "./styles/styled.cards"
import TickImg from "./imgs/tick.png";
import DiagnosisImg from "./imgs/diagnosis.png";
import ExperienceImg from "./imgs/experience.png"
import CalendarImg from "./imgs/calendar.png"
import WalkImg from "./imgs/walk.png"
import MedicalImg from "./imgs/medical.png"
import {useAnimation} from "framer-motion"
import {useInView} from "react-intersection-observer";

const fadeVariants = {
  visible: {opacity: 1, transition: { duration: 0.8}},
  hidden: {opacity: 0}
}

function Info() {

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if(inView) {
      controls.start("visible")
    }
  }, [inView, controls])

  return (
    <InfoContainer 
    ref={ref}
    variants={fadeVariants}
    initial="hidden"
    animate={controls}
    >
            {/* <InfoImageContainer>
                <InfoImage src={InfoImg} />
            </InfoImageContainer>
         */}
         <InfoHolder>

        <InfoItemContainer>
            <InfoItemBackground>
            <InfoItemHeading>Let me help you to improve your <p style={{'display': 'inline', 'lineHeight': "0px", 'fontFamily': 'Caveat', 'fontSize': '3.5rem'}}> well-being.</p></InfoItemHeading>
            </InfoItemBackground>
        </InfoItemContainer>

         <InfoCardContainerMainHolder>
          <InfoCardContainerHolder>

            <InfoCardContainerText>
            <InfoItemText><p style={{'display': 'inline-block', 'fontWeight': "700"}}>Physiotherapy</p> is a medical profession that understands pain and pathology on a <p style={{'display': 'inline' , 'fontWeight': "700"}}>cellular level</p>. We change and alter healing, by influencing physiological processes in your body. At <p style={{'display': 'inline' , 'fontWeight': "700"}}>Advanced Theraphy</p>, our goal is to improve your performance to get you back to your regular routine as fast and as safely as possible.</InfoItemText>
            </InfoCardContainerText>

            <InfoCardContainer>

            <InfoCard>
                <InfoCardImage src={DiagnosisImg} />
                <InfoCardText>Thorough diagnosis and assessments.</InfoCardText>
              </InfoCard>

              <InfoCard>
                <InfoCardImage src={ExperienceImg} />
                <InfoCardText>Improve your performance with long term solutions.</InfoCardText>
              </InfoCard>

              <InfoCard>
                <InfoCardImage src={CalendarImg} />
                <InfoCardText>Experience handled with care.</InfoCardText>
              </InfoCard>

              <InfoCard>
                <InfoCardImage src={WalkImg} />
                <InfoCardText>Walk-in's accepted.</InfoCardText>
              </InfoCard>

              <InfoCard>
                <InfoCardImage src={MedicalImg} />
                <InfoCardText>Medical aids accepted and rates applied.</InfoCardText>
              </InfoCard>

            </InfoCardContainer>

          </InfoCardContainerHolder>

         </InfoCardContainerMainHolder>
         
         
        </InfoHolder>
    </InfoContainer>
  )
}

export default Info