import styled from "styled-components";

export const CardsContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;

    flex-flow: wrap;
    flex-direction: column;
    justify-content: center;
    
`

export const CardsItem = styled.div`
    display: flex;
    width: 100%;
    padding: 10px;

    align-items: center;
    flex-flow: wrap;
    margin-bottom: 10px;
    
`

export const CardItemImageHolder = styled.div`
    margin-right: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
`

export const CardsItemImage = styled.img`
    width: 30px;
    margin-left: 10px;
    height: auto;
    
`

export const CardsItemTextHolder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
`

export const CardsItemText = styled.p`
    text-align: center;
    font-size: 0.8rem;
    color: black;
`