import styled from "styled-components";
import BookImg from "../imgs/book.png"
import {motion} from "framer-motion"

export const BookContainer = styled.div`
    width: 100%;

    margin-top: 30px;
    padding: 50px 20px;

    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-image: url(${BookImg});
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;


`

export const BookImage = styled(motion.div)`
    display: flex;
    justify-content: flex-end;
    z-index: 1;
    width: 100%;
    height: 100%;
    

    
`

export const BookTextHolder = styled.div`

    display: flex;
    max-width: 600px;
    z-index: 2;
    padding: 20px 0px;
    flex-direction: column;
    border-radius: 10px;
    background-color: rgba(233, 236, 239, 0.6);
    border: 4px solid #e9ecef;

    

`

export const BookText = styled.h1`
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    padding: 0px 20px;
    margin-bottom: 20px;
    font-weight: 600;
    color: #343a40;

    @media (max-width: 600px) {
        font-size: 1.2rem;
        text-align: center;
    }

    @media (max-width: 300px) {
        padding: 0px;
    }
`

export const BookButton = styled.button`
    outline: 0;
    border: 0;
    width: 100%;
    padding: 10px 40px;
    width: 80%;
    text-transform: uppercase;
    padding: 10px 0px;
    position: relative;
    display: inline-block;
    letter-spacing: 2px;

    border-radius: 5px;
    font-weight: 600;
    color: white;
    background-color: #f50000;
    overflow: hidden;
    transition: 0.3s;

    &:after {
        content: "►";
        position: absolute;
        padding-left: 10px;
        transition: all 0.3s ease-in-out;
        opacity: 0;
    }

    &:hover {
        letter-spacing: 4px;
        cursor: pointer;
        opacity: 0.7;

        &::after {
            opacity: 1;
        }
    }
`