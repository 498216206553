import styled from "styled-components";
import {motion} from "framer-motion";

export const NavContainer = styled.nav`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    color: #666A6D;
    padding: 0px;
    background-color: #EBEFF2;
    z-index: 300;
    border-bottom: 5px solid #343a40;
`

export const NavInnerContainer = styled.div`
    display: flex;
    width: 100%;
    height: 60px;

`

export const NavExpandedContainer = styled.div`
    display: ${props => props.showExpanded ? "flex" : "none"};
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0, 0.9); 
    z-index: 200;
    overflow-x: hidden; 
    position: fixed;

`

export const NavLeftContainer = styled.div`
    display: flex;
    flex: 1;
    margin-left: 20px;
    
    justify-content: flex-start;
`

export const NavLeftContainerText = styled.h2`
    display: flex;
    width: 100%;
    align-items: center;
    color: #343a40;
    text-transform: uppercase;
    font-size: 0.8rem;
    margin-left: 20px;
    margin-right: 10px;

    @media (max-width: 800px) {
        display: none;
    }
`

export const NavRightContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
`

export const NavQuoteContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
`

export const NavRightIconContainer = styled(motion.div)`
    display: none;
    z-index: 301;
    color: white;
    padding: 5px;
    border-radius: 50%;
    background-color: white;

    &:hover {
        cursor: pointer;
    }

    @media (max-width: 800px) {
        display: flex;
        margin-right: 20px;
    }
`

export const NavLogoHolder = styled.div`

    display: flex;
    z-index: 300;
    align-items: center;
    justify-content: flex-start;
`

export const NavLogo = styled.img`
    width: 60px;
    height: 50px;
    z-index: 301;
`

export const NavItemContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;

    @media (max-width: 800px) {
        display: none;
    }
`
export const NavExpandedItemContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    
    justify-content: center;

`

export const NavExpandedItem = styled.div`
    font-size: 1.8rem;
    text-transform: lowercase;
    margin-top: 20px;
    font-weight: 700;
    color: white;
    text-align: center;
    transition: all 0.3s ease-in-out;

    &:hover {
        cursor: pointer;
        color: #cdcdcd;
        font-weight: 700;
        letter-spacing: 2px;
    }
`

export const NavItem = styled.div`
    margin-right: 30px;
    font-size: 0.8rem;
    text-transform: lowercase;
    font-weight: 500;
    transition: all 0.3s ease-in-out;

    &:hover {
        cursor: pointer;
        color: #cdcdcd;
    }
`

export const NavQuoteItem = styled.button`
    height: 40px;
    width: 200px;
    background-color: white;
    color: black;
    border-radius: 5px;
    outline: none;
    border: none;
    font-weight: 800;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    text-transform: uppercase;

    &:hover {
        cursor: pointer;
    }
`