import styled from "styled-components"
import {motion} from "framer-motion"
import HeroImg from "../imgs/hero.png";

export const HeroContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: relative;
    
    display: flex;
    z-index: 7;
    justify-content: center;
    border-bottom: 5px solid #343a40;
    background: url(${HeroImg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    @media (max-width: 800px) {
        padding-top: 50px;
        z-index: 2;
        
        align-items: center;

    }

`

export const VideoContainer = styled.div`
    width: 100%;
    height: 100vh;
`

export const Video = styled.video`
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: 0;
    position: relative;
`

export const VideoOverlay = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(52, 58, 64, 0.7);
`

export const HeroHeading = styled(motion.h1)`
        text-transform: uppercase;
        font-weight: 700;
        text-align: center;
        font-size: 40px;
        margin: 0;
        width: 100%;
        display: flex;
        align-self: center;
        justify-content: center;

        @media (max-width: 600px) {
            text-align: center;
            font-size: 35px;
        }

        @media (max-width: 350px) {
            font-size: 25px;
        }
`

export const HeroSection = styled.div`
    display: flex;
    align-self: center;
    width: 70%;
    padding: 40px 40px;
    flex-direction: column;
    z-index: 6;
    padding-bottom: 40px;
    background-color: rgba(248, 249, 250, 0.7);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 2px;
    color: black;
    
    @media (max-width: 500px) {
        width: 90%;
    }


`

export const HeroSubtitle = styled.p`

        width: 100%;
        margin: 5px 0px;
        margin-bottom: 10px;
        font-size: 0.9rem;
        font-weight: 500;
        text-align: center;
        
        color: #343a40;
        max-width: 400px;
        padding-left: 0px;
        display: flex;
        align-self: center;


    @media (max-width: 600px) {
        text-align: center;
        font-size: 0.9rem;

        margin-top: 5px;
    }

    @media (max-width: 400px) {
            font-size: 0.8rem;
            max-width: max-content;
        }
`

export const HeroTitle = styled.p`
    color: #f40000;
    width: 100%;
    font-size: 0.8rem;
    
    font-weight: 600;
    text-align: center;
    letter-spacing: 1.2px;
    padding-left: 3px;

    @media (max-width: 600px) {
        width: 100%;
        padding: 0px;
        text-align: center;
        font-size: 0.7rem;

    }
`

export const HeroButton = styled.button`
    outline: 0;
    border: 0;
    text-transform: uppercase;
    margin-top: 30px;
    width: 80%;
    padding: 10px 30px;
    max-width: 300px;
    letter-spacing: 2px;
    border-radius: 5px;
    font-weight: 600;
    color: white;
    background-color: #6c757d;
    transition: 0.3s;
    justify-self: center;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;

    &:after {
        content: "►";
        position: absolute;
        padding-left: 10px;
        transition: all 0.3s ease-in-out;
        opacity: 0;
    }

    &:hover {
        letter-spacing: 4px;
        cursor: pointer;
        opacity: 0.8;

        &::after {
            opacity: 1;
        }
    }

    @media (max-width: 600px) {
        width: 100%;
        margin-top: 20px;
    }
`