import React, { useEffect } from 'react'
import { CardItemImageHolder, CardsHolder, CardsItemContainer, CardsContainer, CardsHeader, CardsHeaderBackground, CardsHeaderContainer, CardsItem, CardsItemHeading, CardsItemImage, CardsItemText, CardsItemTextHolder } from "./styles/styled.cards"
import BackPainImg from "./imgs/backpain.jpg";
import AnklePainImg from "./imgs/anklepain.jpg"
import HipPainImg from "./imgs/hippain.jpg"
import KneePainImg from "./imgs/kneepain.jpg"
import NeckPainImg from "./imgs/neckpain.jpg";
import ShouldPainImg from "./imgs/shoulderpain.jpg";
import AnkleFractureImg from "./imgs/footpain.jpg";
import AccidentImg from "./imgs/accidentpain.jpg";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer"

const squareVariants = {
    visible: { opacity: 1, transition: { duration: 1.5 } },
    hidden: { opacity: 0 }
}

function Cards() {
    const controls = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView])

    return (
        <CardsContainer
            id="treatments"
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={squareVariants}>

            <CardsHeaderContainer>
                <CardsHeaderBackground>
                    <CardsItemHeading>Treatments</CardsItemHeading>
                </CardsHeaderBackground>
            </CardsHeaderContainer>

            <CardsHolder>
                <CardsItemContainer>
                    <CardsItem>
                        <CardItemImageHolder name="Back Pain">
                            <CardsItemImage src={BackPainImg} />
                        </CardItemImageHolder>
                        <CardsItemTextHolder>
                            <CardsItemText>Some of the causes of lower back pain are the muscles, joints, nerves, discs and tendons. Physiotherapists look at the symptoms and types of pain to make a diagnosis.</CardsItemText>
                        </CardsItemTextHolder>
                    </CardsItem>

                    <CardsItem>
                        <CardItemImageHolder name="Ankle Pain">
                            <CardsItemImage src={AnklePainImg} />
                        </CardItemImageHolder>
                        <CardsItemTextHolder>
                            <CardsItemText>Different tissues may cause ankle pain. Injury to muscles, tendons, ligaments can cause ankle joint pain and swelling.</CardsItemText>
                        </CardsItemTextHolder>
                    </CardsItem>

                    <CardsItem>
                        <CardItemImageHolder name="Hip Pain">
                            <CardsItemImage src={HipPainImg} />
                        </CardItemImageHolder>
                        <CardsItemTextHolder>
                            <CardsItemText>Hip pain is complex & not many medical practitioners are able to pinpoint the main source of your hip pain. Put us to the challenge!</CardsItemText>
                        </CardsItemTextHolder>
                    </CardsItem>

                    <CardsItem>
                        <CardItemImageHolder name="Knee Pain">
                            <CardsItemImage src={KneePainImg} />
                        </CardItemImageHolder>
                        <CardsItemTextHolder>
                            <CardsItemText>Knee pain can be related to an injury to any of the knee tissues like ligaments, muscles, tendons, meniscus, cartilage & bone. Knee injuries occur suddenly, usually by twisting or turning your knee.</CardsItemText>
                        </CardsItemTextHolder>
                    </CardsItem>

                    <CardsItem>
                        <CardItemImageHolder name="Neck Pain">
                            <CardsItemImage src={NeckPainImg} />
                        </CardItemImageHolder>
                        <CardsItemTextHolder>
                            <CardsItemText>The symptoms of neck pain often overlap. Neck pain is described by muscle pain & stiffness or a sharp stabbing neck pain when you turn your head. To find the cause of the pain, we look at the different structures in the neck, muscles, nerves and joints.</CardsItemText>
                        </CardsItemTextHolder>
                    </CardsItem>

                    <CardsItem>
                        <CardItemImageHolder name="Shoulder Pain">
                            <CardsItemImage src={ShouldPainImg} />
                        </CardItemImageHolder>
                        <CardsItemTextHolder>
                            <CardsItemText>The causes of shoulder pain depend on the injured structure within the shoulder joint. Symptoms give us a clue as to what structure might be injured.</CardsItemText>
                        </CardsItemTextHolder>
                    </CardsItem>

                    <CardsItem>
                        <CardItemImageHolder name="Foot Pain">
                            <CardsItemImage src={AnkleFractureImg} />
                        </CardItemImageHolder>
                        <CardsItemTextHolder>
                            <CardsItemText>Your foot is a complex structure that consists of 26 bones, 33 joints, and over 100 muscles, tendons, and ligaments. Its unique design allows your foot and ankle to handle heavy forces every day. We examine the foot to find the cause of the pain being experienced.</CardsItemText>
                        </CardsItemTextHolder>
                    </CardsItem>

                    <CardsItem>
                        <CardItemImageHolder name="Accident Recovery">
                            <CardsItemImage src={AccidentImg} />
                        </CardItemImageHolder>
                        <CardsItemTextHolder>
                            <CardsItemText>After an accident, where the body has had significant injury, we conduct a general assessment and put together a comprehensive recovery plan to get you back on your feet as soon as possible</CardsItemText>
                        </CardsItemTextHolder>
                    </CardsItem>
                </CardsItemContainer>
            </CardsHolder>

        </CardsContainer>
    )
}

export default Cards