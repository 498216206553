import React from 'react'
import {BookContainer, BookText, BookButton, BookImage, BookTextHolder} from "./styles/styled.book";
import { NavLink } from './styles/styled.appcontainer';

function Book() {
  return (
    <BookContainer>
        <BookTextHolder>
            <BookText>Wondering if your medical aid will cover you?</BookText>
            <NavLink href='tel:0659113810' target="_blank"><BookButton>Call us</BookButton></NavLink>
        </BookTextHolder>

    </BookContainer>
  )
}

export default Book