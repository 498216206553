import styled from "styled-components";

export const ContactContainer = styled.div`
    width: 100%;
    display: flex;
    padding: 40px 50px;
    margin-top: 30px;
    justify-content: space-between;
    background-color: white;
    align-items: center;
    border: 4px solid white;

    @media (max-width: 900px) {
        flex-direction: column;
        justify-content: center;
        padding: 20px 30px;
        align-items: center;
    }

`

export const ContactLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    
    @media (max-width: 900px) {
        margin-bottom: 20px;
        text-align: center;
    }
`

export const ContactRightContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const ContactContainerHeading = styled.h1`

    margin-bottom: 20px;
    font-size: 1.2rem;
    
    @media (max-width: 900px) {
        font-size: 0.9rem;
        margin-top: 0px;
        text-align: center;
    }
`

export const ContactContainerText = styled.p`
    width: 100%;
    display: flex;
    align-items: center;

    margin-bottom: 3px;

    font-weight: 400;
    font-size: 0.8rem;

    @media (max-width: 900px) {
        font-size: 0.7rem;
        justify-content: center;
        margin-bottom: 5px;
    }
`

export const ContactEndContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    transition: 0.3s;
    max-width: 300px;
    
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media (max-width: 900px) {
        margin-top: 20px;
    }

    @media (max-width: 400px) {
        width: 100%;
    }

    &:after {
        position: absolute;
        content: "Directions";
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 600;
        letter-spacing: 2px;
        color: black;
        z-index: 2;
        left: 50%;
        display: none;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover {
        

        &::after {
            display: block;
            opacity: 1;
        }
    }
`

export const ContactDirectionsImage = styled.img`
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    object-fit: cover;
    border-radius: 10px;
    transition: 0.3s;
    
    @media (max-width: 600px) {
        max-width: 300px;
    }


    &:hover {
        opacity: 0.5;
    }


    
`