import styled from "styled-components";
import {motion} from "framer-motion"

export const InfoContainer = styled(motion.div)`

    
    display: flex;

    margin-top: 40px;
    width: 100%;
    align-items: center;
    color: black;
    overflow: hidden;

    flex-direction: column;
    
    transition: all 0.3s ease-in-out;

`

export const InfoHolder = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;

`

export const InfoItemContainer = styled.div`
    display: flex;
    z-index: 2;
    padding-left: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
        padding-left: 50px;
    }
    
`

export const InfoItemBackground = styled.div`
    
    z-index: 1;
    display: flex;
    align-items: center;
    
    margin-top: 20px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 90px;
    padding: 20px 50px;
    padding-bottom: 30px;
    width: 100%;
    background-color: #418ae1;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.04) 0px 1px 2px;
`

export const InfoImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 900px) {
        width: 100%;
        align-items: center;
        justify-content: center;
    }
`

export const InfoImage = styled.img`
    max-width: 400px;
    height: auto;

    @media (max-width: 900px) {
        width: 100vw;
    }
    
`

export const InfoItemHeading = styled.h1`
    font-weight: 700;
    z-index: 3;
    display: block;
    width: 100%;
    line-height: 1.7;
    padding: 0;
    padding-right: 40px;
    font-size: 2.2rem;
    text-align: left;
    color: white;

    @media (max-width: 600px) {
        font-size: 2rem;
        padding-right: 20px;
    }

`

export const InfoItemSubHeading = styled.h3`
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
`

export const InfoItemText = styled.p`

    font-size: 1rem;
    line-height: 2;
    max-width: 700px;
    display: block;
    align-self: center;

    @media (max-width: 1000px) {
        font-size: 0.9rem;
    }

`
export const InfoCardContainerMainHolder = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
`

export const InfoCardContainerHolder = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0px;

    @media (max-width: 1000px) {
        flex-direction: column;
    }
`

export const InfoCardContainerText = styled.div`
    display: flex;
    border-radius: 20px;

    height: 80%;
    background-color: #e9ecef;
    padding: 20px 40px;
    border: 4px solid #e9ecef;

    max-width: 550px;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin-right: 40px;

    @media (max-width: 1000px) {
        max-width: max-content;
        margin-right: 20px;
        margin-left: 20px;
        margin-bottom: 30px;
        padding-top: 40px;
        padding-bottom: 40px;
        
    }
`

export const InfoCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0px 0px;

    @media (max-width: 1000px) {
        margin-right: 20px;
        margin-left: 20px;
        margin-bottom: 0px;
        padding-bottom: 10px;
    }
`

export const InfoCard = styled.div`
    padding: 10px 20px;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 20px 40px;
    padding-right: 60px;
    margin-top: 10px;

    border-radius: 20px;
    background-color: #e9ecef;
    padding: 20px 40px;
    border: 2px solid #f8f9fa;

    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    color: black;

    @media (max-width: 500px) {
        width: 100%;
        margin-left: 0px;

    }


    
`

export const InfoCardImage = styled.img`
    width: 30px;
    height: 30px;
    margin-right: 20px;
`

export const InfoCardText = styled.p`
    font-size: 0.8rem;
    text-align: left;
    width: 100%;
`

export const InfoCardItem = styled.div`
    display: flex;
    max-width: 300px;
    flex-flow: wrap;
    margin: 10px;
    border-radius: 10px;
    flex-direction: column;
`
