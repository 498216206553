import styled from "styled-components";
import {motion} from "framer-motion"

export const CardsContainer = styled(motion.div)`
    width: 100%;
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    margin-top: 20px;
    padding-bottom: 20px;
`

export const CardsHeaderContainer = styled.div`
    display: flex;
    z-index: 2;
    
    flex-direction: column;

`

export const CardsHeaderBackground = styled.div`
    
    z-index: 1;
    display: flex;
    align-items: center;
    margin-top: 20px;
    border-top-right-radius: 90px;
    border-bottom-right-radius: 90px;
    padding: 20px 50px;
    padding-bottom: 20px;
    width: 70%;
    background-color: #418ae1;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.04) 0px 1px 2px;
`

export const CardsItemHeading = styled.h1`
    font-weight: 700;
    z-index: 3;
    width: 100%;
    padding: 0;

    font-size: 2.2rem;
    text-align: left;
    color: white;

    @media (max-width: 600px) {
        font-size: 1.7rem;
    }

    

`


export const CardsHeader = styled.h1`
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    color: #4361ee;
    
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 40px;
`

export const CardsHolder = styled.div`
    width: 100%;
    display: flex;
    padding: 30px;
    justify-content: center;
`

export const CardsItemContainer = styled.div`
    display: flex;
    flex-flow: wrap;
    justify-content: center;
`



export const CardsItem = styled(motion.div)`
    display: flex;
    max-width: 300px;
    flex-flow: wrap;
    border: 2px solid white;
    margin: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    background-color: #fdfdff;
    border: 3px solid #f3f2ff;
    align-items: center;
    border-radius: 10px;
    padding: 0px 0px;
    padding-bottom: 10px;
    flex-direction: column;
`

export const CardItemImageHolder = styled.div`
    display: flex;
    width: 100%;
    height: 200px;
    
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: all 0.5s ease-in-out;
    

    &::after {
        transition: opacity 1s ease-in-out;
        position: absolute;
        content: '${props => props.name ? props.name : ""}';
        text-transform: uppercase;
        font-size: 0.9rem;
        font-weight: 600;
        background-color: rgba(255, 255, 255, 0.3);
        width: 100%;
        height: 100%;
        letter-spacing: 2px;
        color: black;
        opacity: 0;
        text-align: center;
        display: none;
        justify-content: center;
        align-items: center;
        z-index: 20;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover {
        cursor: pointer;
        transform: scale(1.1);
        &::after {
            transition: opacity 1s ease-in-out;
            opacity: 1;
            display: flex;
        }
    }


`

export const CardsItemImage = styled.img`

    height: 100%;
    width: 100%;
    object-fit: cover;
    
`

export const CardsItemTextHolder = styled.div`
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 15px;
    line-height: 1.7;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
`

export const CardsItemText = styled.p`
    text-align: center;
    font-size: 0.8rem;
`