import styled from "styled-components";

import { motion } from "framer-motion";
import AboutPic from "../imgs/about.png";

export const AboutContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 20px 0px;

`

export const AboutHolder = styled.div`
    display: flex;
    flex: 90%;
    max-width: 1000px;
    margin-right: 50px;
    background: url(${AboutPic});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;

    @media (max-width: 1024px) {
        flex-direction: column;
        margin-right: 10px;
    }
`

export const AboutLeftContainer = styled.div`
    display: flex;
    padding: 80px 20px;
    max-width: 650px;

    @media (max-width: 600px) {
        padding: 30px 0px;
        max-width: max-content;
    }

`

export const AboutTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 20px;

`

export const AboutHeading = styled.h1`
    text-transform: uppercase;
    font-size: 3rem;
    color: #495057;
    margin-left: 10px;
    width: 100%;


`

export const AboutText = styled.p`

    font-size: 0.9rem;
    padding: 40px 20px;
    padding-right: 30px;
    font-weight: 500;
    line-height: 1.9;
    border-radius: 10px;
    background-color: rgba(233, 236, 239, 0.7);
    border: 4px solid #e9ecef;


    color: #343a40;
 
    display: flex;

    @media (max-width: 500px) {
        
        font-size: 0.9rem;
    }
`

export const AboutRightContainer = styled.div`
    display: flex;
    flex: 100%;

    @media (max-width: 500px) {
        opacity: 0.7;
   
    }
`


export const AboutImage = styled(motion.img)`

    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (max-width: 500px) {
        max-width: 150px;
    }
`