import React from 'react'
import {Carousel, CarouselItem} from "./Carousel"
import {CarouselHolderHeading, CarouselImage, CarouselText, CarouselTextAuthor, CarouselTextHolder} from "./styles/styled.carousel"

function CarouselHolder() {
  return (
    <>
    <CarouselHolderHeading>Testimonials</CarouselHolderHeading>
    <Carousel>
        <CarouselItem>
        <CarouselTextHolder>
            <CarouselText>I can now run like Husain Bolt. And I can jump and jump and go here and there and everywhere.</CarouselText>
            <CarouselTextAuthor>Imraan M.</CarouselTextAuthor>
        </CarouselTextHolder>
        
        </CarouselItem>

        <CarouselItem>
        <CarouselTextHolder>
            <CarouselText>Beep Beep.</CarouselText>
            <CarouselTextAuthor>Imraan M.</CarouselTextAuthor>
        </CarouselTextHolder>
        </CarouselItem>
        
        <CarouselItem>
        <CarouselTextHolder>
            <CarouselText>Abdul Aziz! You cured me!</CarouselText>
            <CarouselTextAuthor>Imraan M.</CarouselTextAuthor>
        </CarouselTextHolder>
        </CarouselItem>

        <CarouselItem>
        <CarouselTextHolder>
            <CarouselText>Who can spell physiotherapist?</CarouselText>
            <CarouselTextAuthor>Imraan M.</CarouselTextAuthor>
        </CarouselTextHolder>
        </CarouselItem>

      </Carousel>
    </>
    
  )
}

export default CarouselHolder