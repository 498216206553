import React, {useState, useEffect} from 'react'
import { NavContainer, NavLogo, NavInnerContainer, NavLeftContainer, NavRightIconContainer, NavRightContainer, NavLogoHolder, NavItemContainer, NavItem, NavExpandedContainer, NavExpandedItemContainer, NavExpandedItem, NavQuoteContainer, NavQuoteItem, NavLeftContainerText} from "./styles/styled.navbar";
import {GrClose} from "react-icons/gr";
import {FiMenu} from "react-icons/fi";
import LogoImg from "./imgs/logo.png"

function Navbar() {

const [showExpanded, setShowExpanded] = useState(false);

function handleScroll(item) {
    
  const violation = document.getElementById(item); 
  violation.scrollIntoView({ behavior: 'smooth' });

  setShowExpanded(false);
}

  return (
    <NavContainer>
              <NavInnerContainer>
                <NavLeftContainer>
                <NavLogoHolder>
                  <NavLogo src={LogoImg} />
                </NavLogoHolder>
                <NavLeftContainerText>Advanced Theraphy</NavLeftContainerText>
                </NavLeftContainer>

                {/* <NavQuoteContainer>
                  <NavQuoteItem>Make a booking</NavQuoteItem>
                </NavQuoteContainer> */}
                
                <NavRightContainer>
                  
                  <NavRightIconContainer
                    whileHover={{scale: 0.9, opacity: 0.8}}
                    onClick={() => setShowExpanded(prev => !prev)}>
                      {!showExpanded ? <FiMenu size={32} color="black" /> : 
                      <GrClose size={32} color="white" />}
                  </NavRightIconContainer>
                  
                  <NavItemContainer>
                    <NavItem 
                    onClick={() => handleScroll("home")}>Home</NavItem>
                    <NavItem 
                    onClick={() => handleScroll("about")}>About</NavItem>
                    <NavItem 
                    onClick={() => handleScroll("treatments")}>treatments</NavItem>
                    {/* <NavItem 
                    onClick={() => handleScroll("testimonials")}>Testimonials</NavItem> */}
                    <NavItem 
                    onClick={() => handleScroll("contact")}>Contact</NavItem>
                  </NavItemContainer>
                
                </NavRightContainer>

                
              </NavInnerContainer>
              <NavExpandedContainer showExpanded={showExpanded}>
                <NavExpandedItemContainer>
                    <NavExpandedItem 
                    onClick={() => handleScroll("home")}>Home</NavExpandedItem>
                    
                    <NavExpandedItem 
                    onClick={() => handleScroll("about")}>About</NavExpandedItem>
                    
                    <NavExpandedItem 
                    onClick={() => handleScroll("treatments")}>treatments</NavExpandedItem>
                    {/* <NavExpandedItem 
                    onClick={() => handleScroll("testimonials")}>Testimonials</NavExpandedItem> */}
                    <NavExpandedItem 
                    onClick={() => handleScroll("contact")}>Contact</NavExpandedItem>
                </NavExpandedItemContainer>
              </NavExpandedContainer>
            </NavContainer>
  )
}

export default Navbar