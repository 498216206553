import React from 'react'
import {FooterContainer, FooterText} from "./styles/styled.footer"
import {NavLink} from "./styles/styled.appcontainer"

function handleWhatsAppClick() {
  window.open("https://www.cortadodigital.com", '_blank', 'noopener,noreferrer');
}

function Footer() {
  return (
    <FooterContainer  onClick={() => handleWhatsAppClick()}>
        <FooterText>Advanced Therapy. All Rights Reserved. 2022.</FooterText>
        <FooterText>Website developed by Cortado Digital</FooterText>
    </FooterContainer>
  )
}

export default Footer