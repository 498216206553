import React from "react";
import {AppContainer, WhatsappButton} from "./styles/styled.appcontainer"
import Navbar from "./Navbar";
import Hero from "./Hero";
import Cards from "./Cards";
import Info from "./Info";
import Treatments from "./Treatments";
import Book from "./Book";
import About from "./About";
import CarouselHolder from "./CarouselHolder";
import Contact from "./Contact";
import Footer from "./Footer";

function App() {

  function handleWhatsAppClick() {
    window.open("https://wa.me/+27767413538", '_blank', 'noopener,noreferrer');
  }

  return (
    <AppContainer id="home">
      <Navbar />
      <Hero />
      <About />
      <Info />
      <Cards />
      {/* <CarouselHolder /> */}
      <Book />
      <Contact />
      <Footer />
      <WhatsappButton onClick={() => handleWhatsAppClick()} />
      
    </AppContainer>
    
  );
}

export default App;
